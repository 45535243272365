// import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
// import { isMobile, isMobileTab } from '../../jsutility/index'
import axios from 'axios';
import Slider from "react-slick";
import FsLightbox from 'fslightbox-react';



import PropertyList from "./PropertyList"
// Style
import './FeaturedProperties.scss';
// import { truncate } from "lodash";

import { inViewOptions, containerVariants, delayItemsFromBottom, fadeInFromTop } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const FeaturedProperties = (props) => {
    const [isPlaying, setPlaying] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [lightBoxImages, setLightBoxImages] = useState([]);
    const [lightBoxIndex, setLightBoxIndex] = useState('s0');
    const [key, setKey] = React.useState('sale');
    const [letTitle, setLetTitle] = React.useState('');
    
    const [propItems, setPropItems] = useState(false)
    const [propItemslet, setPropItemslet] = useState(false)
    const [letProgress, setLetProgress] = useState("loading...")
    const [saleProgress, setSaleProgress] = useState("loading...")



    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {
                    headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                    }})
            setPropItems(data)
            setSaleProgress("No Result Found")
            data.map((item, i) => {
                if (item.images) {
                    var list = []
                    item.images.map((img) => {
                        list.push(img.srcUrl)
                    })
                    lightBoxImages['sale_image_' + i] = (list)
                }
            })
            data.map((item, i) => {
                if (item.floorplan) {
                    var list = []
                    item.floorplan.map((img) => {
                        list.push(img.srcUrl)
                    })
                    lightBoxImages['sale_floor_' + i] = (list)
                }
            })
            setLightBoxImages(lightBoxImages)

        } catch (error) {
            console.error(error)
        }
    }

    const getitemsone = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            setPropItemslet(data);
            setLetProgress("No Result Found")
            data.map((item, i) => {
                if (item.images) {
                    var list = []
                    item.images.map((img) => {
                        list.push(img.srcUrl)
                    })
                    lightBoxImages['let_image_' + i] = (list)
                }
                if (item.floorplan) {
                    var list = []
                    item.floorplan.map((img) => {
                        list.push(img.srcUrl)
                    })
                    lightBoxImages['let_floor_' + i] = (list)
                }
            })
            setLightBoxImages(lightBoxImages)
        } catch (error) {
            console.error(error)
        }
    }
    let sale_infinite = false
    let let_infinite = false
    if (propItems.length >= 3) {
        sale_infinite = true
    }
    if (propItemslet.length >= 3) {
        let_infinite = true
    }
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: sale_infinite,
        arrows: false,
        mobileFirst: true,
        autoplay: true,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    }

    const settings_let = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: let_infinite,
        arrows: false,
        mobileFirst: true,
        autoplay: true,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    }

    useEffect(() => {
        let officeId = props.officeId ?? "";
        let area = (props.Place != null) ? props.Place : null;
        let building = props.building ?? "";

        let url = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=sales&_limit=8&_sort=published_at:DESC&publish=true"; // TODO: base URL supposed to be from .env
        if (area) {
            url = url + "&display_address_contains=" + area;
        }
        if (building) {
            url = url + "&building=Villa";
        }
        if (officeId) {
            url = url + "&office_crm_id=" + officeId;
        }


        let url_let = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&_limit=8&_sort=published_at:DESC&publish=true"; // TODO: base URL supposed to be from .env
        if (area) {
            url_let = url_let + "&display_address_contains=" + area;
        }
        if (building) {
            url_let = url_let + "&building=Villa";
        }
        if (officeId) {
            url_let = url_let + "&office_crm_id=" + officeId;
        }
        if(props.Title_With_Collection === "Featured_Properties_Rent"){
            setKey('let') 
        }
        
        if(props.Title_With_Collection === "Featured_Properties"){
            setLetTitle(`Property to Rent ${(props.Place != null && props.Place != "") ? "in "+props.Place : ''}`)
        }

        getitems(url)
        getitemsone(url_let)
    }, [])

    let fshow = "hide"
    if (propItems.length != 0 || propItemslet.length != 0) {
        fshow = "show"
    }


    const openImageLightBox = (i) => {
        setLightBoxIndex(i)
        setTimeout(() => {
            setToggler(!toggler)
        }, 50);
    }
    return (
        <React.Fragment>
            {fshow == 'show' &&
                <InView {...inViewOptions}>
                    {({ ref, inView }) => (
                        <motion.section
                            className="feautued-properties"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <Container>
                                <Row>
                                    <Col>
                                        <div className="properties">
                                            <motion.span className="sm-text" variants={fadeInFromTop}>{props.Title}</motion.span>
                                            <motion.p variants={fadeInFromTop}>{props.Sub_Title}</motion.p>
                                            <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="uncontrolled-tab-example" className={`${letTitle ==""? "no-properties": null }`}>
                                                {(props.Title_With_Collection === "Featured_Properties" || props.Title_With_Collection === "Featured_Properties_Sale") &&
                                                    <Tab eventKey="sale" title={`Property for Sale ${(props.Place != null && props.Place != "") ? "in "+ props.Place : ''}`}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                {propItems.length ?
                                                                    <Slider  {...settings}>
                                                                        {propItems.map((item, index) => {
                                                                            return (
                                                                                <motion.div
                                                                                    className="tab-content"
                                                                                    key={index}
                                                                                    custom={index}
                                                                                    variants={delayItemsFromBottom}
                                                                                >
                                                                                    <PropertyList {...item} lightBox={openImageLightBox} type="sale" index={index} />
                                                                                </motion.div>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </Slider> : <p className="no-result-found">{saleProgress}</p>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Tab>
                                                }
                                                {(props.Title_With_Collection === "Featured_Properties" || props.Title_With_Collection === "Featured_Properties_Rent") &&
                                                    <Tab eventKey="let" title={letTitle}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                {propItemslet.length ?
                                                                    <Slider  {...settings_let}>
                                                                        {propItemslet.map((item, index) => {
                                                                            return (
                                                                                <motion.div
                                                                                    className="tab-content"
                                                                                    key={index}
                                                                                    custom={index}
                                                                                    variants={delayItemsFromBottom}
                                                                                >
                                                                                    <PropertyList {...item} lightBox={openImageLightBox} type="letter" index={index} />
                                                                                </motion.div>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </Slider> : <p className="no-result-found">{letProgress}</p>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Tab>
                                                }
                                            </Tabs>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </motion.section>
                    )}
                </InView>
            }
            <FsLightbox
                toggler={toggler}
                sources={lightBoxImages[lightBoxIndex]}
                type='image'
                key={lightBoxIndex}
            />
        </React.Fragment>
    )
}
export default FeaturedProperties